import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
    Redirect,
    Route,
    RouteComponentProps,
    RouteProps,
    useHistory,
    useLocation,
} from "react-router-dom";
import { getGroupData, GroupData } from "Api/backend";
import jwtDecode from "jwt-decode";
import { Context, Filters, SelectedDates } from "State/store";
import { presetRanges } from "Components/DateFilter";
import { useWebviewLogin } from "Hooks/useWebviewLogin";
import { useLogout } from "Components/Mobile/MobileSettingsScreen/MobileSettingsScreen";
import { useAuth } from "@hooks/useAuth";
import { RoutePaths } from "./Routes";
import { usePreviewMode } from "Store/viewSettingsStore";
import { useInitializeFilter } from "Store/filterStore";
import CubeJsProvider from "Components/CubeJsProvider";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { Auth } from "../../Api/auth/auth";
import { useMsal } from "@azure/msal-react";

const useCustomHooks = () => {
    const location = useLocation();
    const isHomePage = location.pathname === "/";
    const isLoading = useWebviewLogin();
    const logout = useLogout();
    return { location, isHomePage, isLoading, logout };
};

/**
 * A Route wrapper component that checks for a valid user session
 */
interface AuthCheckRouteProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>>;
}
const AuthCheckRoute = ({
    component: Component,
    ...rest
}: AuthCheckRouteProps): ReactElement => {
    const { location, isLoading, logout } = useCustomHooks();
    const { isLoggedIn } = useAuth(); // update this line
    const [loaded, setLoaded] = useState(false);
    const [{ groupData }, dispatch] = useContext(Context);
    const { setPreviewMode } = usePreviewMode();
    const initializeFilter = useInitializeFilter();
    const msalUUID = `https://launcher.myapps.microsoft.com/api/signin/${process.env.REACT_APP_MSAL_UUID}?tenantId=b8f7b107-c1cc-4e76-a3e3-2d7fa7f1e226`;

    const router = useHistory();
    const authZero = new Auth();
    useEffect(() => {
        document.title = "Salesline - Login";
    }, []);

    const { instance, accounts } = useMsal();

    useEffect(() => {
        const getMsToken = async () => {
            if (accounts.length > 0) {
                try {
                    const response = await instance.acquireTokenSilent({
                        scopes: ["User.Read", "Application.Read.All"],
                        account: accounts[0],
                    });
                    // Store the ms token in localStorage
                    localStorage.setItem("ms_token", response.accessToken);
                } catch (error) {
                    console.error("Token acquisition failed:", error);
                }
            }
        };
        getMsToken();
    }, [instance, accounts]);

    useEffect(() => {
        if (!isLoggedIn) {
            if (authZero.isIdPInitiatedSSO()) {
                authZero
                    .silentAuth()
                    .then(() => {
                        window.location.reload();
                    })
                    .catch((err) => {
                        if (err.error === "consent_required") {
                            console.warn(
                                "Consent required. Redirecting to login..."
                            );
                            authZero.interactiveAuth();
                        } else {
                            console.error("Silent authentication failed:", err);
                            // Handle other errors appropriately
                        }
                    });
            } else {
                window.location.href = msalUUID;
            }
        } else if (!accounts[0]) {
            window.location.href = "/login";
        }
    }, [router, loaded, isLoggedIn]);

    useEffect(() => {
        const groupdataGetting = async () => {
            await getGroupData()
                .then((data) => {
                    dispatch({
                        type: "ADD_GROUP_DATA",
                        payload: {
                            groupData: data,
                        },
                    });
                })
                .catch((error) => {
                    // throw new Error(error);
                });
        };
        if (isLoggedIn) {
            groupdataGetting();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoading) return;
        if (isLoggedIn) {
            handleGroupData(groupData, setPreviewMode, dispatch, initializeFilter)
                .then(() => {
                    setLoaded(true);
                })
                .catch((e) => {
                    console.log("LOG OUT EVENT");
                    console.error({ e });
                    // logout();
                });
        }
    }, [isLoggedIn, isLoading]);
    if (!isLoggedIn || !loaded) {
        return <QuantacoLoader size={200} />;
    }
    return (
        <CubeJsProvider>
            <Route {...rest} component={Component} />
        </CubeJsProvider>
    );
};

const handleGroupData = async (
    groupData: GroupData | undefined,
    setPreviewMode: (value: boolean) => void,
    dispatch: any,
    initializeFilter: any
) => {
    if (groupData === undefined) {
        await getGroupData()
            .then(async (groupData: GroupData) => {
                let datasetName = "";
                try {
                    datasetName = (jwtDecode(groupData.cubejsToken) as any).user
                        .dataset_name;

                    setPreviewMode(true);
                } catch (e) {
                    console.error(e);
                }

                // Process the retrieved groupData and update the state
                processGroupData(groupData, datasetName, dispatch, initializeFilter);
            })
            .catch((e) => {
                console.log("ISSUE FETCHING GROUP DATA");
                console.error({ e });
                throw e;
                // logout();
            });
    }
};

const processGroupData = (
    groupData: GroupData,
    datasetName: string,
    dispatch: any,
    initializeFilter: any
) => {
    dispatch({
        type: "ADD_GROUP_DATA",
        payload: {
            groupData,
        },
    });

    dispatch({
        type: "UPDATE_FILTERS",
        payload: {
            filters: {
                datasetName,
                mode: "actual",
            } as Filters,
        },
    });

    if (groupData.user && groupData.user.settings?.defaultViewId) {
        const defaultViewId = groupData.user.settings.defaultViewId;
        const view = groupData.views.views.find(
            (view) => view.view_id === defaultViewId
        );
        if (view) {
            const selectedDates: SelectedDates = view.filters.selectedDates;
            let { fromDate, toDate } = selectedDates;
            if (selectedDates.isPresetDateRange) {
                const { presetDateRangeLabel } = selectedDates;
                const selectedRange = presetRanges.find(
                    (range) => range.label === presetDateRangeLabel
                )!;
                const [fromDateObj, toDateObj] = selectedRange.range();
                fromDate = fromDateObj.format("YYYY-MM-DD");
                toDate = toDateObj.format("YYYY-MM-DD");
            }

            const filters = {
                ...view.filters,
                selectedDates: {
                    ...selectedDates,
                    fromDate,
                    toDate,
                },
                selectedViewId: defaultViewId,
            };

            initializeFilter({
                selectedDateRange: {
                    start: fromDate,
                    end: toDate,
                },
                selectedComparison: view.filters.selectedComparison
                    ? (view.filters.selectedComparison as any)
                    : undefined,
            });

            dispatch({
                type: "UPDATE_FILTERS",
                payload: {
                    filters,
                },
            });
        }
    }
};

export default AuthCheckRoute;
